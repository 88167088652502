@if(showNotif){
<div class="error-notif"><fa-icon [icon]="faCircleInfo"></fa-icon>{{showNotifText}}</div>
}

<div class="page-deposit-desktop">
    <app-header-simple />
    <div class="container-xxl m-auto">
        @if(showError){
        <div class="page-change-payment page-error">
            <div class="container-xxl m-auto">
                <div class="row align-items-center container-change-promo d-flex py-5 pt-3 mb-5">
                    <div class="container-payment">
                        <div class="pembayaran-error">
                            <span class="image-success">
                                <fa-icon [icon]="faShield" [size]="'9x'"></fa-icon>
                                <fa-icon [icon]="faXmark" [size]="'4x'"></fa-icon>
                            </span>
                            <span class="text-pembayaran">Mohon maaf, waktu pembayaran anda sudah habis.<br> Mohon hubungi
                                CS kami jika anda mengalami kendala.</span>
                        </div>
                        <button type="button" class="btn btn-redirect mt-3" [disabled]="disabledButtonSub"
                            (click)="cancelAutoPayment()">Kembali</button>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
        }
        @if(!showError){
            @if(loader) {
                <div class="spinner-container">
                    <div class="spinner-border" style="margin: 0 auto;color: var(--accent-main-text-color);" role="status"></div>
                </div>
            }@else{
                <div class="row main-deposit container-auto-deposit d-flex my-5">
                    <div class="side-bar-deposit"
                        [ngStyle]="{'height':(statusPage === 'form' && (currentMethodPayment === 'qris' || currentMethodPayment === 'vacc'))? '505px': (statusPage === 'success' && (currentMethodPayment === 'qris' || currentMethodPayment === 'vacc'))? '505px': (statusPage === 'confirm' && (currentMethodPayment === 'qris' || currentMethodPayment === 'vacc'))?'384px':'505px'}">
                        <div class="status-balance-user">
                            <div class="badge-user">
                                <img src="../../../template/green/assets/images/status-gold.png">
                            </div>
                            <div class="data-user">
                                <span class="group-user">
                                    <img
                                        src="../../../template/green/assets/images/rank/{{(userGroupData['name']).toLowerCase()}}.webp">
                                </span>
                                <span class="username">{{userProfile['login_id']}}</span>
                                @if(balance == undefined){
                                <span class="balance">Loading</span>
                                } @else {
                                <span class="balance">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</span>
                                }
                            </div>
                        </div>
                        @if(depositConfig['status']){
                            @if(depositConfig['qris']){
                            <div class="pay-qris" [ngClass]="currentMethodPayment === 'qris' ? 'active-button':''"
                                (click)="selectMethodPayment('qris')">
                                <span class="img-icon">
                                    <img src="../../../template/green/assets/images/qris.png">
                                </span>
                                <span class="text-menu">QRIS</span>
                                <fa-icon [icon]="faCircleChevronRight"></fa-icon>
                            </div>
                            }
                            @if(depositConfig['virtual_account']){
                            <div class="pay-vacc" [ngClass]="currentMethodPayment === 'vacc' ? 'active-button':''"
                                (click)="selectMethodPayment('vacc')">
                                <span class="img-icon">
                                    <img src="../../../template/green/assets/images/vacc.png">
                                </span>
                                <span class="text-menu">Virtual Account</span>
                                <fa-icon [icon]="faCircleChevronRight"></fa-icon>
                            </div>
                            }
                        }
                        <div class="divider mt-3"></div>
                        <div class="container-payment-bank" [ngStyle]="{'height':(statusPage === 'form' && (currentMethodPayment === 'qris' || currentMethodPayment === 'vacc'))? 'calc(100% - 125px)': (statusPage === 'success' && (currentMethodPayment === 'qris' || currentMethodPayment === 'vacc'))? 'calc(100% - 185px)': (statusPage === 'confirm' && (currentMethodPayment === 'qris' || currentMethodPayment === 'vacc'))?'calc(100% - 185px)':(statusPage === 'form' && currentMethodPayment === 'bank')? 'calc(100% - 20px)': statusPage === 'payment' ? 'calc(100% - 90px)': (statusPage === 'create-bank' || statusPage === 'confirm-bank')  ? 'calc(100% - 125px)':'auto'}">
                            <span class="title-divider">Bank Transfer</span>
                            @for (item of bankCompanyList; track $index) {
                                <div class="pay-bank" [ngClass]="currentMethodPayment === 'bank' && currentActiveAccount === item._id? 'active-button':''"
                                    (click)="selectMethodPayment('bank'); selectActiveBank(item)">
                                    <span class="img-icon">
                                        <img src="{{item.system_bank.images.small}}">
                                    </span>
                                    <span class="text-menu">{{item.system_bank.name}}</span>
                                    <fa-icon [icon]="faCircleChevronRight"></fa-icon>
                                </div>
    
                            }
                        </div>
                    </div>
                    @if(statusPage === 'success'){
                    <div class="container-payment">
                        <div class="pembayaran-berhasil">
                            <span class="image-success">
                                <fa-icon [icon]="faShield" [size]="'9x'"></fa-icon>
                                <fa-icon [icon]="faCheck" [size]="'4x'"></fa-icon>
                            </span>
                            <span class="text-pembayaran">PEMBAYARAN BERHASIL</span>
                        </div>
                        <button type="button" class="btn btn-redirect mt-3" [disabled]="disabledButtonSub"
                            (click)="goToTransaction()">Kembali Ke Riwayat
                            Transaksi</button>
                        <div></div>
                        <button type="button" class="btn btn-auto-redirect" [disabled]="disabledButtonSub">Otomatis kembali ke
                            riwayat
                            transaksi ({{timeCountSecond}}s)</button>
                    </div>
                    }@else{
                        @if(statusPage === 'form'){
                        <div class="container-payment">
                            <div class="input-amount">
                                <span class="title-deposit">
                                    <span>DEPOSIT</span>
                                    @if(currentMethodPayment === 'bank'){
                                        <span class="button">{{currentActiveBank['system_bank']['name']}} (Bank Tansfer)</span>
                                    }
                                </span>
                                @if(currentMethodPayment === 'bank'){
                                <div class="choose-bank">
                                    <span class="image">
                                        <img [src]="currentDataBankUser['system_bank']['images']['small']">
                                    </span>
                                    <span class="user-detail">
                                        <a class="name">{{currentDataBankUser['account_name']}}</a>
                                        <a class="rek">{{currentDataBankUser['account_number'] | splitNumber}}</a>
                                    </span>
                                    <button type="button" class="btn btn-trigger" (click)="statusPage = 'payment'">
                                        Ganti Rekening Anda
                                    </button>
                                </div>
                                }
                                <div class="d-flex w-100" style="gap: 15px;margin-top: 20px;">
                                    <div class="d-inline-block" [ngClass]="currentMethodPayment === 'bank'? 'w-60':'w-100'">
                                        <label class="label-input">Masukan Nominal Deposit</label>
                                        <div class="container-input"
                                        [ngStyle]="{'border-color':isCorrectValue !== 0 ? 'red':'rgba(255, 255, 255, 0.5)'}">
                                        <label for="inputAmount" class="label-input-small">Masukan Nominal</label>
                                            @if (currentMethodPayment === 'bank') {
                                                <input id="inputAmount" type="text" spellcheck="false"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                    class="form-control" placeholder=" "
                                                    [ngModel]="(inputAmount | currency:' ' : 'symbol' : '1.0-0')"
                                                    (ngModelChange)="updateValueNominal($event)" name="inputAmount"
                                                    aria-label="Masukan Nominal" />
                                            } @else {
                                            <label class="container-extra" for="inputAmount">
                                                <input id="inputAmount" type="text" spellcheck="false"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control"
                                                    placeholder=" " [ngModel]="(inputAmount | currency:' ' : 'symbol' : '1.0-0')"
                                                    (ngModelChange)="updateValueNominal($event)" name="inputAmount" aria-label="Masukan Nominal" />
                                                <div class="extra-seribu">.000,-</div>
                                            </label>
                                            }
                                        </div>
                                        
                                        <!-- <div class="chip-amount-container">
                                            <swiper-container appSwiper [config]="config" class="swiper-container">
                                                @for (item of arrayNominal; track item;) {
                                                <swiper-slide class="swiper-item">
                                                    <div class="chip-amount" [ngClass]="currentNominal === item ? 'active-chip':''"
                                                        (click)="setNominalChip(item)">Rp {{item | currency:' ' : 'symbol' : '1.0-0'}}</div>
                                                </swiper-slide>
                                                }
                                            </swiper-container>
                                        </div> -->
                                    </div>
                                    @if(currentMethodPayment === 'bank'){
                                        <div class="d-inline-block w-40">
                                            <textarea class="notes" [formControl]="noteDeposit" name="catatan" placeholder="Tambahkan Catatan" type="text"></textarea>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="choose-promo">
                                <label class="label-input">Pilih Voucher Promo</label>
                                <div class="d-inline-block w-100">
                                    @if (currentPromo['_id']) {
                                    <div class="container-promo-bar active-promo" [ngClass]="disabledButton? 'pointer-off':''">
                                        <div class="area-click" (click)="openPromoSearch()">
                                            <span class="img-icon">
                                                <img src="../../../template/green/assets/images/voucher_applied.png">
                                            </span>
                                            <span class="text-status display-600-none">Voucher Applied</span>
                                            <span class="text-name-promo">{{currentPromo['title']}}</span>
                                        </div>
                                        <fa-icon [icon]="faTrashCan" (click)="resetPromo()"></fa-icon>
                                    </div>
                                    } @else {
                                    <div class="container-promo-bar" [ngClass]="disabledButton? 'pointer-off':''"
                                        (click)="openPromoSearch()">
                                        <span class="img-icon">
                                            <img src="../../../template/green/assets/images/voucher.png">
                                        </span>
                                        <span class="text-status">Voucher Promo</span>
                                        <span>Pilih Voucher</span>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div class="transaction-detail">
                                <div class="container-rule-payment">
                                    <span>
                                        <fa-icon [icon]="faCircleInfo"></fa-icon>
                                        @if(currentMethodPayment ==="bank"){
                                            @if(!currentActiveBank['system_bank']['transaction_fee_percentage'] && !currentActiveBank['system_bank']['transaction_fee']){
                                                Transaction Fee FREE
                                            }@else if(!currentActiveBank['system_bank']['transaction_fee_percentage'] && currentActiveBank['system_bank']['transaction_fee']){
                                                Transaction Fee {{currentActiveBank['system_bank']['transaction_fee'] | currency:' ' : 'symbol' : '1.0-0'}}
                                            }@else{
                                            Transaction Fee {{currentActiveBank['system_bank']['transaction_fee_percentage']*100}}% {{currentActiveBank['system_bank']['transaction_fee'] == 0 ?
                                            '':'(minimum: '+(currentActiveBank['system_bank']['transaction_fee'] | currency:' ' : 'symbol' : '1.0-0')+')'}}
                                            }
                                        }@else{
                                            @if(depositConfig['handled_by'] === 1){
                                                @if(currentMethodPayment ==="qris"){
                                                    Transaction Fee {{depositConfig['qris_fee']*100}}% {{depositConfig['qris_fee_fix_amount'] == 0 ?
                                                    '':'(minimum: '+(depositConfig['qris_fee_fix_amount'] | currency:' ' : 'symbol' : '1.0-0')+')'}}
                                                }@else{
                                                    Transaction Fee {{depositConfig['virtual_account_fee']*100}}%
                                                    {{depositConfig['virtual_account_fee_fix_amount'] == 0 ? '':'(minimum: '+(depositConfig['virtual_account_fee_fix_amount'] | currency:' ' : 'symbol' : '1.0-0')+')'}}
                                                }
                                            } @else {
                                                Transaction Fee FREE
                                            }
                                        }
                                    </span>
                                    @if(currentMethodPayment ==="bank"){
                                        <span>
                                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                                            Minimum Jumlah Deposit {{minDPAmount | currency:' ' : 'symbol' : '1.0-0'}}
                                        </span>
                                        <span>
                                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                                            Maksimum Jumlah Deposit {{maxDPAmount | currency:' ' : 'symbol' : '1.0-0'}}
                                        </span>
                                    }@else{
                                        <span>
                                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                                            Minimum Jumlah Deposit {{currentMethodPayment === 'qris'? (depositConfig['qris_min_amount'] | currency:' ' : 'symbol' : '1.0-0') : (depositConfig['virtual_account_min_amount'] | currency:' ' : 'symbol' : '1.0-0')}}
                                        </span>
                                        <span>
                                            <fa-icon [icon]="faCircleInfo"></fa-icon>
                                            Maksimum Jumlah Deposit {{currentMethodPayment === 'qris'? (depositConfig['qris_max_amount'] | currency:' ' : 'symbol' : '1.0-0') : (depositConfig['virtual_account_max_amount'] | currency:' ' : 'symbol' : '1.0-0')}}
                                        </span>

                                    }
                                </div>
                                <div class="container-payment-detail">
                                    <div class="jumlah-deposit">
                                        <span>Jumlah Deposit</span>
                                        @if (currentMethodPayment === 'bank') {
                                            <span>IDR {{inputAmount ? (inputAmount | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                                            
                                        } @else {
                                            <span>IDR {{inputAmount ? ((inputAmount * 1000) | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                                        }
                                    </div>
                                    <div class="biaya-transaksi">
                                        <span>Biaya Transaksi</span>
                                        @if(currentMethodPayment === 'bank'){
                                            <span>
                                            {{currentActiveBank['system_bank'] ? currentActiveBank['system_bank']['transaction_fee'] ||
                                            currentActiveBank['system_bank']['transaction_fee']? 'IDR ' + (currentFee | currency:' ' : 'symbol' : '1.0-0'):'FREE' :''}}
                                            </span>
                                        }@else {
                                            @if(depositConfig['handled_by'] === 1){
                                                @if(currentMethodPayment ==="qris"){
                                                <span>IDR {{inputAmount ? (((inputAmount * 1000) *
                                                    (depositConfig['qris_fee']))+depositConfig['qris_fee_fix_amount'] | currency:' ' : 'symbol'
                                                    : '1.0-0') : 0}}</span>
                                                }@else{
                                                <span>IDR {{inputAmount ? (((inputAmount * 1000) *
                                                    (depositConfig['virtual_account_fee']))+depositConfig['virtual_account_fee_fix_amount'] |
                                                    currency:' ' : 'symbol' : '1.0-0') :
                                                    0}}</span>
                                                }
                                            } @else {
                                            FREE
                                            }
                                        }
                                    </div>
                                    <div class="total-pembayaran">
                                        <span>Total Diterima</span>
                                        @if(currentMethodPayment === 'bank'){
                                            <span>
                                                Rp {{inputAmount ? ((amountDepositNumber - currentFee < 0 ? 0:amountDepositNumber - currentFee) | currency:' ' : 'symbol' : '1.0-0' ) : 0}}
                                            </span>
                                        }@else {
                                            @if(depositConfig['handled_by'] === 1){
                                                @if(currentMethodPayment ==="qris"){
                                                <span>IDR {{inputAmount ? ((inputAmount * 1000)-(((inputAmount * 1000) *
                                                    (depositConfig['qris_fee']))+depositConfig['qris_fee_fix_amount']) | currency:'
                                                    ' : 'symbol' : '1.0-0') : 0}}</span>
                                                }@else{
                                                <span>IDR {{inputAmount ? ((inputAmount * 1000)-(((inputAmount * 1000) *
                                                    (depositConfig['virtual_account_fee']))+depositConfig['virtual_account_fee_fix_amount']) |
                                                    currency:' ' : 'symbol' :
                                                    '1.0-0') :
                                                    0}}</span>
                                                }
                                            }@else{
                                            <span>IDR {{inputAmount ? ((inputAmount * 1000) | currency:' ' : 'symbol' : '1.0-0') : 0}}</span>
                                            }
                                        }
                                    </div>
                                </div>
                            </div>
                
                            <button type="button" id="deposit-button" class="btn track-deposit btn-confirm-deposit mt-2"
                                (click)="confirmDeposit(currentMethodPayment)" [disabled]="disabledButton">
                                {{inProgress ? 'DALAM PROSES':'DEPOSIT'}}
                            </button>
                        </div>
                        } 
                        @if (statusPage === 'confirm') {
                        <div class="container-payment container-detail-transfer">
                            <span class="title-deposit-payment">HARAP SEGERA MELAKUKAN PEMBAYARAN</span>
                            <div class="area-qris-payment">
                                <div class="qris-image">
                                    <!-- <qrcode [imageSrc]="websiteJSONData['header']['favicon']" [imageHeight]="50" [imageWidth]="50" (qrCodeURL)="onChangeURL($event)" [qrdata]="autoData['paymentgateway_link']" [width]="256" [errorCorrectionLevel]="'M'">
                                                                        </qrcode> -->
                                    <qrcode (qrCodeURL)="onChangeURL($event)" [qrdata]="autoData['paymentgateway_link']" [width]="210"
                                        [imageHeight]="210" [errorCorrectionLevel]="'M'">
                                    </qrcode>
                
                                    <a [href]="qrCodeDownloadLink" download="qrcode" class="btn btn-download-qr mt-1">Download QR</a>
                                </div>
                                <div class="area-detail-container">
                                    <div class="area-detail-payment">
                                        <div class="container-detail">
                                            <div class="qris-detail">
                                                <span class="user-name">{{ autoData['paymentgateway_store_name'] ? autoData['paymentgateway_store_name'] : autoData['paymentgateway_name']}}</span>
                                            </div>
                                            <div class="transfer-detail">
                                                <div class="nominal-transfer">IDR {{autoData ? (autoData['amount'] | currency:' ' :
                                                    'symbol' : '1.0-0') : 0}}</div>
                                            </div>
                                            <div class="time-expired">
                                                Berlaku Hingga <span class="count-time">({{ minutesShow }}:{{ secondsShow }})</span>
                                            </div>
                                        </div>
                                        <div class="container-button-qris mt-1">
                                            <button type="button" class="btn btn-cancel-deposit" [disabled]="disabledButtonSub"
                                                (click)="cancelAutoPayment()">Batalkan Pembayaran</button>
                                            <button type="button" class="btn btn-check-deposit" [disabled]="disabledButtonSub"
                                                (click)="checkStatusDepositAuto()">Konfirmasi Pembayaran</button>
                                        </div>
                                    </div>
                
                                </div>
                            </div>
                            
                        </div>
                        }
                        @if (statusPage === 'confirm-bank') {
                        <div class="container-payment container-detail-transfer">
                            <div class="area-detail-payment">
                                <div class="detail-bank">
                                    <span class="sub-title">Lakukan Pembayaran via Bank</span>
                                    <div class="bank-data">
                                        <img src="{{manualData['to_bank']['system_bank']['images']['small']}}">
                                        <span class="bank-name">{{manualData['to_bank']['system_bank']['name']}}</span>
                                    </div>
                                </div>
                                <div class="vacc-detail">
                                    <span class="sub-title">Nomor Rekening Tujuan</span>
                                    <span class="vacc-number">{{manualData['to_bank']['account_number'] | splitNumber}} <a class="copy-button" (click)="copyToClipboard(manualData['to_bank']['account_number'])">copy</a></span>
                                </div>
                                <div class="transfer-detail">
                                    <span class="sub-title">Jumlah Transfer</span>
                                    <div class="nominal-transfer">IDR {{manualData ? (manualData['amount'] | currency:' ' :
                                        'symbol' : '1.0-0') : 0}}</div>
                                </div>
                            </div>
                            <div class="upload-image">
                                <label class="label-upload" for="image-pembayaran">
                                    @if(base64ImageData === ''){
                                    <fa-icon [icon]="faImages" [size]="'xl'"></fa-icon>
                                    <span>Upload Bukti Pembayaran</span>
                                    } @else {
                                    <div class="container-base64">
                                        <img src="{{base64ImageData}}">
                                        <span class="name-file">{{imageFileName}}</span>
                                    </div>
                                    }
                                </label>
                                <input #imageInput style="display: none;" type="file" class="form-control" id="image-pembayaran"
                                    name="image-pembayaran" placeholder="Upload bukti pembayaran" (change)="onSelectFile($event)" />
                            </div>
                            <button type="button" class="btn btn-submit-deposit mt-2" (click)="gotoTransaction()">Cek Status Pembayaran</button>
                            <button type="button" class="btn btn-back" (click)="statusPage = 'form'">Kembali</button>
                        </div>
                        }
                        @if (statusPage === 'create-bank') {
                            <div class="page-create-payment">
                                <div class="main-create-bank">
                                    <div class="create-payment-container">
                                        <div class="sub-title">Tambah Rekening Anda</div>
                                        <div class="input-select-payment">
                                            <div ngbDropdown class="d-inline-block w-100">
                                                <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank"
                                                    ngbDropdownToggle>
                                                    <fa-icon class="icon-left" [icon]="faMoneyCheckDollar" [size]="'lg'"></fa-icon>
                                                    @if(currentSelectCreateBank === 'bank'){
                                                    <span class="selected-title">BANK</span>
                                                    }@else {
                                                    <span class="selected-title">E-WALLET</span>
                                                    }
                                                </button>
                                                <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                                                    @if(listSelectCreateBank.length > 0){
                                                    <button ngbDropdownItem (click)="selectBankCreate('bank')">
                                                        BANK
                                                        @if(currentSelectCreateBank === 'bank'){
                                                        <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                                                        }
                                                    </button>
                                                    }
                                                    @if(listSelectCreateEwallet.length > 0){
                                                    <button ngbDropdownItem (click)="selectBankCreate('ewallet')">
                                                        E-WALLET
                                                        @if(currentSelectCreateBank === 'ewallet'){
                                                        <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                                                        }
                                                    </button>
                                                    }
                                                </div>
                                            </div>
                                            <div ngbDropdown class="d-inline-block w-100">
                                                <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank"
                                                    ngbDropdownToggle>
                                                    <span class="img">
                                                        @if(currentSelectedCreateBank['images']){
                                                        <img [src]="currentSelectedCreateBank['images']['small']"
                                                            [alt]="currentSelectedCreateBank['name']">
                                                        }
                                                    </span>
                                                    <span class="bank-name">{{currentSelectedCreateBank['name']}}</span>
                                                </button>
                                                <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                                                    <div class="w-100 search-create-bank">
                                                        <input type="text" class="form-control" autocomplete="off"
                                                            [(ngModel)]="inputSearchCreate" (keyup)="onSearchcreateValue($event)"
                                                            placeholder="Search Bank.." name="searchBankInput" aria-label="Search Bank.." />
                                                        <button type="button" class="btn">
                                                            <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                                                        </button>
                                                    </div>
                                                    @for (item of searchedCreateOptions; track item;) {
                                                    <button ngbDropdownItem (click)="selectedCreateBank(item)">
                                                        <span class="img">
                                                            <img [src]="item['images']['small']" [alt]="item['name']">
                                                        </span>
                                                        <span class="bank-name">{{item['name']}}</span>
                                                        @if(currentSelectedCreateBank['_id'] === item['_id']){
                                                        <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                                                        }
                                                    </button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-no-rek">
                                            <label for="userBankNum" class="label-input-small">
                                                <fa-icon class="icon-left" [icon]="faCreditCard" [size]="'lg'"></fa-icon>
                                            </label>
                        
                                            <input id="userBankNum" type="text" spellcheck="false"
                                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                class="form-control" [(ngModel)]="inputUserBankNum" [placeholder]="'Nomor Rekening'"
                                                name="userBankNum" aria-label="Nomor Rekening" />
                                        </div>
                                        <div class="input-user">
                                            <label for="userBankName" class="label-input-small">
                                                <fa-icon class="icon-left" [icon]="faUser" [size]="'lg'"></fa-icon>
                                            </label>
                        
                                            <input id="userBankName" type="text" spellcheck="false" class="form-control"
                                                [(ngModel)]="inputUserBankName" [disabled]="true" [placeholder]="'Nama Rekening'"
                                                name="userBankName" aria-label="Nama Rekening" />
                                        </div>
                                        <button class="btn btn-submit-create" [disabled]="disabledButtonCreate" (click)="createBankAcc()">Tambah
                                            Rekening</button>
                                        <button class="btn btn-back" [disabled]="disabledButtonCreate" (click)="statusPage = 'payment'">Kembali</button>
                                    </div>
                                </div>
                            </div>
                        }
                        @if (statusPage === 'payment') {
                            <div class="page-change-payment">
                                <div class="container-change-payment">
                                    <div class="container-input-filter direction-row">
                                        <div class="title-page-small">
                                            Pilih Rekening Anda
                                        </div>
                                        <div class="input-filter">
                                            <input class="form-control" type="text" [(ngModel)]="searchBankInput" placeholder="Cari Rekening..."
                                                (keyup)="onSearchDropdownValue($event)">
                                        </div>
                                        <div ngbDropdown class="position-relative filter-popularity" autoClose="true">
                                            <button class="btn-dropdown px-4" ngbDropdownToggle>
                                                <fa-icon [icon]="faSliders"></fa-icon>
                                                <span>Filter</span>
                                            </button>
                                            <div ngbDropdownMenu class="dropdown-menu select-bank">
                                                <div class="d-flex flex-column gap-2">
                                                    <label ngbDropdownItem for="paymentAll" class="d-flex gap-2"
                                                        [class.active]="currentSelectPaymentType === 'all'"
                                                        (click)="onSelectDropdownValue('all')">
                                                        <!-- <input id="paymentAll" type="checkbox" [checked]="currentSelectPaymentType === 'all'" /> -->
                                                        Tampilkan Semua
                                                    </label>
                                                    <label ngbDropdownItem for="paymentBank" class="d-flex gap-2"
                                                        [class.active]="currentSelectPaymentType === 'bank'"
                                                        (click)="onSelectDropdownValue('bank')">
                                                        <!-- <input id="paymentBank" type="checkbox" [checked]="currentSelectPaymentType === 'bank'" /> -->
                                                        Bank
                                                    </label>
                                                    <label ngbDropdownItem for="paymentEwallet" class="d-flex gap-2"
                                                        [class.active]="currentSelectPaymentType === 'ewallet'"
                                                        (click)="onSelectDropdownValue('ewallet')">
                                                        <!-- <input id="paymentEwallet" type="checkbox" [checked]="currentSelectPaymentType === 'ewallet'" /> -->
                                                        e-Wallet
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container-list">
                                        <div class="container-list-slider">
                                            @for (bank of searchedOptions; track bank;) {
                                            <div class="swiper-item">
                                                <div class="container-payment-bar">
                                                    @if(bank['qr_image'] || bank['system_bank']['code'] === 'qris'){
                                                    <div class="payment-bar"
                                                        [ngClass]="bank['_id'] === currentSelectDataBankUser['_id'] ? 'active':''"
                                                        (click)="selectedBankUser(bank)">
                                                        <div class="payment-logo">
                                                            <div class="image-payment">
                                                                <img [src]="bank['system_bank']['images']['small']"
                                                                    [alt]="bank['system_bank']['name']">
                                                            </div>
                                                            <span>QRIS</span>
                                                        </div>
                                                        <div class="payment-group">
                                                            {{bank['account_name']}}
                                                        </div>
                                                        <div class="payment-number">
                                                            -
                                                        </div>
                                                    </div>
                                                    }
                                                    @if(!bank['qr_image'] && bank['system_bank']['code'] !== 'qris'){
                                                    <div class="payment-bar"
                                                        [ngClass]="bank['_id'] === currentSelectDataBankUser['_id'] ? 'active':''"
                                                        (click)="selectedBankUser(bank)">
                                                        <div class="payment-logo">
                                                            <div class="image-payment">
                                                                <img [src]="bank['system_bank']['images']['small']"
                                                                    [alt]="bank['system_bank']['name']">
                                                            </div>
                                                            <span>{{bank['system_bank']['name']}}</span>
                                                        </div>
                                                        <div class="payment-group">
                                                            {{bank['account_name']}}
                                                        </div>
                                                        <div class="payment-number">
                                                            {{bank['account_number'] | splitNumber}}
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="container-buttons">
                                        @if(transactionConfig['allow_add_bank_account']){
                                        <div class="container-all-buttons">
                                            <div class="container-left-btn">
                                                <div class="btn btn-create-rek" (click)="cancelPage()">Batal</div>
                                            </div>
                                            <div class="container-right-btn">
                                                <div class="btn btn-create-rek" (click)="selectCreateBankOption()">
                                                    <fa-icon class="icon-left me-2" [icon]="faCirclePlus"></fa-icon> Tambah Rekening Anda
                                                </div>
                        
                                                <div class="btn btn-select-rek" (click)="selectPayment()">Pilih Rekening</div>
                                            </div>
                                        </div>
                                        }@else {
                                            <div class="btn btn-create-rek" (click)="cancelPage()">Batal</div>
                                            <div class="btn btn-select-rek" (click)="selectPayment()">Pilih Rekening</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        @if(statusPage === 'promo'){
                        <div class="page-change-payment">
                            <div class="container-change-promo">
                                <span class="title-deposit">
                                    <span>PROMO</span>
                                </span>
                                <div class="container-input-filter">
                                    <label class="label-input">Pilih Promo Menarik</label>
                                    <div class="input-filter">
                                        <form (ngSubmit)="inputSearchPromo()">
                                            <input class="form-control" type="text" [formControl]="searchPromo" placeholder="Cari Promo...">
                                            <button type="submit" class="btn">
                                                <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <div class="container-list-dynamic">
                                    <div class="container-list-promo">
                                        @for (item of promoSearch; track $index) {
                                        <div class="card-promo" (click)="selectedPromo = item">
                                            @if(item['type'] === 'deposit'){
                                            <div class="type-promo">DEPOSIT</div>
                                            <div class="title-promo deposit">{{item['title']}}</div>
                                            <img class="display-600-none" src="../../../template/green/assets/images/deposit_promo.png" />
                                            <img class="display-600-block"
                                                src="../../../template/green/assets/images/deposit_promo_small.png" />
                                            @if(selectedPromo['_id'] === item['_id']){
                                            <fa-icon [icon]="faCircleCheck" [size]="'2x'"></fa-icon>
                                            }
                                            }
                                            @if(item['type'] === 'cashback'){
                                            <div class="type-promo">CASHBACK</div>
                                            <div class="title-promo cashback">{{item['title']}}</div>
                                            <img src="../../../template/green/assets/images/cashback_promo.png" />
                                            @if(selectedPromo['_id'] === item['_id']){
                                            <fa-icon [icon]="faCircleCheck" [size]="'2x'"></fa-icon>
                                            }
                                            }
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div class="container-buttons">
                                    <div class="btn btn-create-rek" (click)="resetPromoBack()">Batal</div>
                                    <div class="btn btn-select-rek" (click)="selectPromo()">Pilih Promo</div>
                                </div>
                            </div>
                        </div>
                        }
                    
                    }
            
                </div>
            }
        
        }
    </div>
    
    <app-footer [data]="footerData" />
</div>
